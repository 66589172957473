export default {
    name: 'us',
    data() {
        return {
            slide: 1,
            adviser: 2,
            nancy: false,
            sophia: false
        }
    }
}
